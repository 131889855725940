import { Algorand } from "~/components/logos/algorand";
import { Aptos } from "~/components/logos/aptos";
import { Arbitrum } from "~/components/logos/arbitrum";
import { Avalanche } from "~/components/logos/avalanche";
import { Base } from "~/components/logos/base";
import { Bitcoin } from "~/components/logos/bitcoin";
import { BNB } from "~/components/logos/bnb";
import { Canton } from "~/components/logos/canton";
import { Cardano } from "~/components/logos/cardano";
import { Celo } from "~/components/logos/celo";
import { Cosmos } from "~/components/logos/cosmos";
import { Ethereum } from "~/components/logos/ethereum";
import { EthereumClassic } from "~/components/logos/ethereum-classic";
import { Flow } from "~/components/logos/flow";
import { Hedera } from "~/components/logos/hedera";
import { Hyperliquid } from "~/components/logos/hyperliquid";
import { InternetComputer } from "~/components/logos/internet-computer";
import { NEAR } from "~/components/logos/near";
import { Optimism } from "~/components/logos/optimism";
import { Polkadot } from "~/components/logos/polkadot";
import { Polygon } from "~/components/logos/polygon";
import { Solana } from "~/components/logos/solana";
import { Stacks } from "~/components/logos/stacks";
import { Stellar } from "~/components/logos/stellar";
import { Sui } from "~/components/logos/sui";
import { Tezos } from "~/components/logos/tezos";
import { TON } from "~/components/logos/ton";
import { Tron } from "~/components/logos/tron";
import { XRP } from "~/components/logos/xrp";
import type { BlockchainId } from "~/lib/stablecoins.server";

const globalClasses = "rounded-full h-6 w-6";

type Props = Readonly<{
  chainId: BlockchainId;
  className?: string;
}>;

export function BlockchainIcon({
  chainId,
  className = "",
}: Props): JSX.Element | null {
  switch (chainId) {
    case "algorand":
      return <Algorand className={`${globalClasses} ${className}`} />;
    case "aptos":
      return <Aptos className={`${globalClasses} ${className}`} />;
    case "arbitrum":
      return <Arbitrum className={`${globalClasses} ${className}`} />;
    case "avalanche":
      return <Avalanche className={`${globalClasses} ${className}`} />;
    case "base":
      return <Base className={`${globalClasses} ${className}`} />;
    case "bitcoin":
      return <Bitcoin className={`${globalClasses} ${className}`} />;
    case "bnb":
      return <BNB className={`${globalClasses} ${className}`} />;
    case "canton":
      return <Canton className={`${globalClasses} ${className}`} />;
    case "cardano":
      return <Cardano className={`${globalClasses} ${className}`} />;
    case "celo":
      return <Celo className={`${globalClasses} ${className}`} />;
    case "cosmos":
      return <Cosmos className={`${globalClasses} ${className}`} />;
    case "ethereum":
      return <Ethereum className={`${globalClasses} ${className}`} />;
    case "ethereum-classic":
      return <EthereumClassic className={`${globalClasses} ${className}`} />;
    case "flow":
      return <Flow className={`${globalClasses} ${className}`} />;
    case "hedera":
      return <Hedera className={`${globalClasses} ${className}`} />;
    case "hyperliquid":
      return <Hyperliquid className={`${globalClasses} ${className}`} />;
    case "internet-computer":
      return <InternetComputer className={`${globalClasses} ${className}`} />;
    case "near":
      return <NEAR className={`${globalClasses} ${className}`} />;
    case "optimism":
      return <Optimism className={`${globalClasses} ${className}`} />;
    case "polkadot":
      return <Polkadot className={`${globalClasses} ${className}`} />;
    case "polygon":
      return <Polygon className={`${globalClasses} ${className}`} />;
    case "solana":
      return <Solana className={`${globalClasses} ${className}`} />;
    case "stacks":
      return <Stacks className={`${globalClasses} ${className}`} />;
    case "stellar":
      return <Stellar className={`${globalClasses} ${className}`} />;
    case "sui":
      return <Sui className={`${globalClasses} ${className}`} />;
    case "tezos":
      return <Tezos className={`${globalClasses} ${className}`} />;
    case "ton":
      return <TON className={`${globalClasses} ${className}`} />;
    case "tron":
      return <Tron className={`${globalClasses} ${className}`} />;
    case "xrp":
      return <XRP className={`${globalClasses} ${className}`} />;

    default:
      return null;
  }
}
